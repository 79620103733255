<template>
  <v-app>
    <v-app-bar app color="#6A76AB" dark src="https://picsum.photos/1920/1080?random">
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>
      <v-container class="py-0 fill-height">
        <v-avatar color="warning lighten-2" size="44" class="mr-10">K</v-avatar>
        <v-toolbar-title>Маңғыстау облысы, Ақтау қаласы, №10 орта мектебі</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark text
              v-bind="attrs"
              v-on="on"
            >
              {{languages[selectedLanguage]}}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              v-for="(item, index) in languages"
              :key="index"
              @click="changeLanguage(index)"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn dark text @click="logout">Выход</v-btn>
      </v-container>
        <template v-slot:extension>
          <v-container class="">
          <v-tabs align-with-title dark class="remove-bg">
            <v-tab to="/school">
              <v-badge
                color="red"
                :value="$store.getters.getUnresolvedRequestCout"
                :content="$store.getters.getUnresolvedRequestCout">
                Журнал заявлений
              </v-badge>
            </v-tab>
          </v-tabs></v-container>
        </template>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container class="py-7">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    mounted() {
      this.$store.dispatch('getUnresolvedRequestCout');
    },
    computed: {
      selectedLanguage() {
        return this.$store.getters.getLanguage;
      }
    },
    data: () => ({
      languages: {
        'kk': 'Қазақша',
        'ru': 'Русский'
      }
    }),
    methods: {
      changeLanguage(lang) {
        this.$store.commit('setLanguage', lang);
        location.reload();
      },
      logout() {
        this.$store.dispatch('logout');
      }
    }
  }
</script>

<style>
  .remove-bg > .v-tabs-bar {
    background-color: transparent !important;
  }
</style>